import jsonTranslations from "./translations.json";

export const CDN = "https://cdn.ozwol.cloud/ozwol/";

export const isValidLang = (lang) => {
  if(lang === "it" || lang === "en"){
    return true;
  }else{
    return false;
  }
}
export const translation = (key, lang) => {
  return <span dangerouslySetInnerHTML={{__html: jsonTranslations[key] && jsonTranslations[key][lang] ? jsonTranslations[key][lang] : "<span style=\"background-color: red; color: white;\">"+key+"|"+lang+"</span>"}} />;
}
export const textTranslation = (key, lang) => {
  return jsonTranslations[key] && jsonTranslations[key][lang] ? jsonTranslations[key][lang] : key+"|"+lang;
}
