import React, {useRef} from 'react';
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
import {translation, textTranslation, CDN} from "./../helpers";

import jsonScripts from "./../data/retouch.json";

import {OzBand, OzContainer, OzRow, OzCol, OzButton, OzUnderline} from "@ozwol/webui";

import Layout from "./../components/Layout";
import Meta from './../components/Meta';
import Scripts from "./../components/Scripts";
import RetouchHero from "./../components/RetouchHero";


const Divider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px dashed #909090;
`;
const Button = styled.div`
  & * {
    vertical-align: middle;
  }
`;

export default function Retouch({canonicalSlug}) {
  const {lang} = useParams();
  const refHowItWorks = useRef();

  return (
    <Layout canonicalSlug={canonicalSlug}>
      <Meta
        title={textTranslation("meta_retouch_title", lang)}
        description={textTranslation("meta_retouch_description", lang)}
        canonicalSlug={canonicalSlug}
      />
      <OzBand variant="chetwodeblue-light">
        <OzContainer>
          <OzRow gap="25" align="center">
            <OzCol widthmd="6">
              <h1>{translation("retouch_title", lang)}</h1>
              {translation("retouch_description", lang)}<br/>
              <br/>
              <Button>
                <OzButton variant="chetwodeblue" onClick={() => {
                  setTimeout(() =>{
                    console.log(refHowItWorks);
                    refHowItWorks.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                   }, 500);
                }}><span className="material-symbols-outlined">
                play_circle
                </span> {translation("retouch_howitworks", lang)}</OzButton>
              </Button>
            </OzCol>
            <OzCol widthmd="6">
              <RetouchHero lang={lang} />
            </OzCol>
          </OzRow>
        </OzContainer>
      </OzBand>
      <OzBand variant="white">
        <OzContainer>
          <center>
            <h3>{translation("ecom_title", lang)}</h3>
            {translation("ecom_description", lang)}
          </center>
        </OzContainer>
      </OzBand>

      <Divider />

      <Scripts items={jsonScripts} lang={lang} format="horizontal" />

      <OzBand variant="chetwodeblue-medium">
        <OzContainer>
          <center>
            <h2>{translation("customization_title", lang)}</h2>
            {translation("customization_description", lang)}<br/>
            <br/><br/>
            <Link to={"/"+lang+"/contact-us"}><OzButton variant="chetwodeblue">{translation("customization_cta", lang)}</OzButton></Link>
          </center>
        </OzContainer>
      </OzBand>
      <div ref={refHowItWorks} style={{scrollMarginTop: "70px"}}>
      <OzBand variant="whitesmoke" >
        <OzContainer>
          <center>
            <h2><OzUnderline variant="chetwodeblue">{translation("workspace_title", lang)}</OzUnderline></h2>
            {translation("workspace_description", lang)}<br/>
            <br/><br/>

            <video style={{width: "900px", maxWidth: "100%"}}  muted loop controls >
              <source src={CDN + "website-assets/ozwol_workspace_ok_1.webm"} type="video/webm" />
              <source src={CDN + "website-assets/ozwol_workspace_ok_1.mp4"} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video><br/>
            <br/><br/>
            <small>
              {translation("workspace_note", lang)}
            </small>
          </center>
        </OzContainer>
      </OzBand>
      </div>
      <OzBand variant="white">
        <OzContainer>
          <center>
            <img src={CDN + "website-assets/psd-icon.png"} style={{width: "80px", maxWidth: "50%"}}  alt="psd icon"/>
            <h2>{translation("psd_title", lang)}</h2>
            {translation("psd_description", lang)}
            <br/><br/>
            <img src={CDN + "website-assets/retouch-psd-levels.png"}  style={{width: "450px", maxWidth: "100%"}}   alt="psd levels"/>
          </center>
        </OzContainer>
      </OzBand>
{/*
     <OzBand variant="white">
        <OzContainer>
          <center>
            <h2>Ozwol’s <br/><OzUnderline variant="chetwodeblue">skills</OzUnderline></h2>
            Gestiamo alta roluzione, file psd, comprimiamo cose, dello studio fotografico e capiamo le richieste del cliente
          </center>
        </OzContainer>
      </OzBand>
*/}
    </Layout>
  )

}
