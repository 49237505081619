import React from 'react';
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
import styled from 'styled-components';

import {OzContainer, WHITESMOKE, OzButton, OzTypo} from "@ozwol/webui";

const Container = styled.div`
  background: ${WHITESMOKE};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export default function NotFound() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow"/>
      </Helmet>
      <Container>
        <OzContainer>
          <center>
            <OzTypo variant="h1w">404</OzTypo>
            Page Not Found<br/><br/>
            <Link to={"/"}>
              <OzButton size="big">Go to Homepage</OzButton>
            </Link>
          </center>
        </OzContainer>
      </Container>
    </>
  )

}
