import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';

import {OzContainer, OzButton} from "@ozwol/webui";
import Script from "./Script";

const HEADER_HEIGHT = 70;
const MENU_HEIGHT = 50;


const Wrapper = styled.div`
  margin-top: 40px;
  position: relative;
`;
const FixableCnt = styled.div`
  height: ${MENU_HEIGHT}px;
`;
const Fixable = styled.div`
  height: ${MENU_HEIGHT}px;
  overflow-x: auto;
  overflow-y: hidden;
  ${props => props.fix === "top" ? `
    position: static;
    ` : props.fix === "inside" ? `
      position: fixed;
      top: `+HEADER_HEIGHT+`px;
      left: 0px;
      width: 100%;
      z-index: 9999;
      background: #EFF1FC;
      border-bottom: 1px solid #dee2e6;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.075);
  ` : props.fix === "bottom" ? `
    position: absolute;
    bottom: `+ (props.viewport-HEADER_HEIGHT-MENU_HEIGHT) +`px;
    left: 0px;
    width: 100%;
    z-index: 99;
    background: #EFF1FC;
    border-bottom: 1px solid #dee2e6;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.075);


  ` : null}
`;
const Filters = styled.div`
  height: ${MENU_HEIGHT}px;
  text-align: center;
  white-space: nowrap;
  & > * {
    margin: 6px 5px 0px;
  }
`;

const Scripts = ({ items, lang, format }) => {
  let arrCategories = items.map((item) => item.category[lang]);
  const [category, setCategory] = useState(arrCategories[0]);
  arrCategories = arrCategories.filter(function(item, pos) {
    return arrCategories.indexOf(item) === pos;
  });
	arrCategories = ["All", ...arrCategories];

  const [scrollListener, setScrollListener] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);

  const handleScroll = useCallback(() => {
    var wrapper = document.getElementById('fixWrp');
    setMenuPosition(
      window.scrollY < (wrapper.offsetTop - HEADER_HEIGHT) ? 
        "top" 
      : window.scrollY > wrapper.offsetTop + wrapper.clientHeight - 0 - window.innerHeight ? 
        "bottom" 
      : 
        "inside"
      );
  }, []);

  useEffect(() => {
    if(!scrollListener){
      setScrollListener(true);
      window.addEventListener("scroll", handleScroll, false);
      handleScroll();
    }
  }, [handleScroll, scrollListener]);

  if(items.length > 0){
    return (
      <Wrapper id="fixWrp">
        <FixableCnt>
          <Fixable fix={menuPosition} viewport={window.innerHeight}>
            <OzContainer>
              <Filters>
                {arrCategories.map((val, i) =>
                  <OzButton key={i} variant={val === category ? "chetwodeblue" : "chetwodeblue-o"} noHover={val === category ? true : false} onClick={() => setCategory(val)}>{val}</OzButton>
                )}
              </Filters>
            </OzContainer>
          </Fixable>
        </FixableCnt>
        {category === "All" ?
          items.map((item, i) => <Script format={format} key={i} lang={lang} item={item} color={i%2 ? "whitesmoke" : "white"} />)
        :
          items.filter(function(item) {
            return item.category[lang] === category;
          }).map((item, i) => <Script format={format} key={i} lang={lang} item={item} color={i%2 ? "whitesmoke" : "white"} />)
        }
      </Wrapper>
    )
  }else{
    return null;
  }
}

export default Scripts;
