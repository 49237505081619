import React from 'react';
import styled from 'styled-components';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {CDN} from "./../helpers";

import {OzBand, OzContainer, OzRow, OzCol} from "@ozwol/webui";

const Wrapper = styled.div`
`;
const Line = styled.div`
  width: 60px;
  height: 5px;
  background-color: #00141f;
`;
const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  color: #00141f;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 991px){
    font-size: 25px;
  }
`;
const Description = styled.div`

`;
const Image = styled.div`
  border: 1px solid #D0D0D0;
  border-radius: 17px;
  box-shadow: 0px 3px 3px #00000019;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: ${props => props.width}px;
  max-width: 100%;

  & img{
    display: block;
    width: ${props => props.width}px;
    max-width: 100%;
  }
`;

const Script = ({ lang, item, color, format }) => {
  return (
    <OzBand variant={color}>
      <OzContainer>
        <Wrapper>
          <OzContainer size={format === "horizontal" ? "medium" : "small"}>
            <OzRow gap="25">
              <OzCol widthmd={format === "horizontal" ? 6 : 12} order={format === "horizontal" ? 1 : 2}>
                <Image
                  width={item.orientation === "landscape" ?
                    (format === "horizontal" ? 400 : 600)
                  :
                    (format === "horizontal" ? 290 : 435)
                  }
                >
                  {item.images.length > 1  ?
                    <Fade
                      duration={3000}
                      arrows={false}
                    >
                      {item.images.map((image, i) => (
                        <img
                          key={i}
                          src={CDN+"website-assets/"+image+".jpg"}
                          alt={item.title[lang]}
                        />
                      ))}
                    </Fade>
                  :
                    <img
                      src={CDN+"website-assets/"+item.images[0]+".jpg"}
                      alt={item.title[lang]}
                    />
                  }
                </Image>
              </OzCol>
              <OzCol widthmd={format === "horizontal" ? 6 : 12} order={format === "horizontal" ? 2 : 1}>
                <Line/>
                <Title dangerouslySetInnerHTML={{__html: item.title[lang]}} />
                <Description dangerouslySetInnerHTML={{__html: item.description[lang]}} />
              </OzCol>
            </OzRow>
          </OzContainer>
        </Wrapper>
      </OzContainer>
    </OzBand>
  )
}

export default Script;
