import React from 'react';
import { Link, useParams, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import {translation, textTranslation} from "./../helpers";

import {CHETWODEBLUE, OzRow, OzCol, OzBand, OzContainer, OzButton, OzIcon, OzTable, OzPlanCard} from "@ozwol/webui";

import Layout from "./../components/Layout";
import Meta from './../components/Meta';
import Card from "./../components/Card";
import Console from "./../chunks/Console";


const PlanCardWrapper = styled.div`
  border-radius: 4px;
  text-align: center;
  color: #01000B;
  height: 100%;
`;

const PlanCardCustomInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const PlanCardCustomBlock = styled.div`
  border-radius: 9px;
  background: ${CHETWODEBLUE}13;
  padding: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const PlanCardCustomNonblock = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 10px;
`;
const PlanCardCustomName = styled.div`
  color: ${CHETWODEBLUE};
  font-weight: 700;
  font-size: 20px;
  line-height: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const PlanCardCustomContent = styled.div`
  color: ${CHETWODEBLUE};
  font-weight: 700;
  font-size: 12px;
`;
const PlanCardCustomCircle = styled.div`
  width: 122px;
  height: 122px;
  border-radius: 122px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
const PlanCardCustomText = styled.div`
  color: ${CHETWODEBLUE};
  font-weight: 700;
  font-size: 12px;
`;


const TableWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;

  & tr td{
    width: 18%;
  }

  & tr td:nth-child(1){
    width: 46%;
  }



  @media (max-width: 991px){

    & tr th:nth-child(1),
    & tr td:nth-child(1){
      display: none;
    }

  }
`;


const ExampleMobile = styled.div`
  display: none;
  font-size: 14px;


  @media (max-width: 991px){
    display: block;

  }
`;

const Icon = styled.div`
  & > * {
    font-size: 50px;
    color: #00141f;
  }
`;


export default function Pricing({canonicalSlug}) {
  const {lang} = useParams();
  let navigate = useNavigate(); 
  return (
    <Layout canonicalSlug={canonicalSlug}>
      <Meta
        title={textTranslation("meta_pricing_title", lang)}
        description={textTranslation("meta_pricing_description", lang)}
        canonicalSlug={canonicalSlug}
      />
      <OzBand variant="whitesmoke">
        <OzContainer>
          <center>
            <h1>{translation("prices_title", lang)}</h1>
            {translation("prices_description", lang)}
          </center>
          <br/><br/>

          <OzRow>
            <OzCol widthmd="3">
              <OzPlanCard 
                lang={lang}
                name={translation("plan_basic", lang)}
                price={59}
                credits="40K"
                creditSingleCost="0,0015"
                rechargeCredits="20K"
                rechargeCost="30"
                maxHotfolders={5}
                maxProjects={1}
                restore={false}
                cta={translation("contactus", lang)}
                ctaDisabled={false}
                onClick={() => navigate("/"+lang+"/contact-us")}
              />
            </OzCol>
            <OzCol widthmd="3">
              <OzPlanCard 
                lang={lang}
                name={translation("plan_pro", lang)}
                price={199}
                credits="160K"
                creditSingleCost="0,0012"
                rechargeCredits="20K"
                rechargeCost="25"
                maxHotfolders={null}
                maxProjects={3}
                restore={"480K"}
                cta={translation("contactus", lang)}
                ctaDisabled={false}
                onClick={() => navigate("/"+lang+"/contact-us")}
              />
            </OzCol>
            <OzCol widthmd="3">
              <OzPlanCard 
                lang={lang}
                name={translation("plan_business", lang)}
                price={499}
                credits="500K"
                creditSingleCost="0,0010"
                rechargeCredits="20K"
                rechargeCost="20"
                maxHotfolders={null}
                maxProjects={10}
                restore={"2.5M"}
                cta={translation("contactus", lang)}
                ctaDisabled={false}
                onClick={() => navigate("/"+lang+"/contact-us")}
              />
            </OzCol>
            <OzCol widthmd="3">
              <PlanCardWrapper>
                <Card height="100%">
                  <PlanCardCustomInner>
                    <PlanCardCustomBlock>
                      <PlanCardCustomName>{translation("plan_custom", lang)}</PlanCardCustomName>

                      <PlanCardCustomContent>
                        <PlanCardCustomCircle>
                        <OzIcon
                          name={"custom"}
                          size={"100px"}
                          color={CHETWODEBLUE}
                        />
                        </PlanCardCustomCircle>
                        <PlanCardCustomText>
                          {translation("plan_custom_description", lang)}
                        </PlanCardCustomText>
                      </PlanCardCustomContent>

                      <div>&nbsp;</div>
                    </PlanCardCustomBlock>
                    <PlanCardCustomNonblock>
                      <br/>
                      <Link to={"/"+lang+"/contact-us"}>
                        <OzButton variant={"chetwodeblue-o"}>{translation("contactus", lang)}</OzButton>
                      </Link>
                      <br/>
                      <br/>
                    </PlanCardCustomNonblock>
                  </PlanCardCustomInner>
                </Card>
              </PlanCardWrapper>
            </OzCol>
          </OzRow>

          <br/><br/>
          <center>
            {translation("prices_note", lang)}
          </center>

        </OzContainer>
      </OzBand>
      <OzBand variant="white">
        <OzContainer>
          <center>
            <Icon>
             <span className="material-symbols-outlined">toll</span>
            </Icon>
            <h3>{translation("credits_title", lang)}</h3>
            {translation("credits_description", lang)}
          </center>
        </OzContainer>
      </OzBand>
      <OzBand variant="whitesmoke">
        <OzContainer>
          <center>
            <h3>{translation("assets_title", lang)}</h3>
            {translation("assets_description", lang)}<br/>
            <br/><br/>
            <TableWrapper>
              <ExampleMobile>{translation("plan_example1", lang)}</ExampleMobile>
              <OzTable
                columns={[
                  "",
                  <center>{translation("plan_basic", lang)}</center>,
                  <center>{translation("plan_pro", lang)}</center>,
                  <center>{translation("plan_business", lang)}</center>
                ]}
                values={[{
                  values: [
                    translation("plan_example1", lang),
                    <center><b>200</b><br/>{translation("plan_processedimages", lang)}</center>,
                    <center><b>800</b><br/>{translation("plan_processedimages", lang)}</center>,
                    <center><b>2500</b><br/>{translation("plan_processedimages", lang)}</center>
                  ]
                }]}
              />
            </TableWrapper>
            <br/><br/>
            {translation("plan_example_divider", lang)}<br/>
            <br/><br/>
            <TableWrapper>
              <ExampleMobile>{translation("plan_example2", lang)}</ExampleMobile>
              <OzTable
                columns={[
                  "",
                  <center>{translation("plan_basic", lang)}</center>,
                  <center>{translation("plan_pro", lang)}</center>,
                  <center>{translation("plan_business", lang)}</center>
                ]}
                values={[{
                  values: [
                    translation("plan_example2", lang),
                    <center><b>266</b><br/>{translation("plan_processedimages", lang)}</center>,
                    <center><b>1066</b><br/>{translation("plan_processedimages", lang)}</center>,
                    <center><b>3333</b><br/>{translation("plan_processedimages", lang)}</center>
                  ]
                }]}
              />
            </TableWrapper>
            <br/><br/>
            {translation("assets_note", lang)}<br/>
            <br/><br/>
            <Link to={"/"+lang+"/contact-us"}><OzButton variant="chetwodeblue">{translation("assets_cta", lang)}</OzButton></Link>
          </center>
        </OzContainer>
      </OzBand>
      <OzBand variant="chetwodeblue-medium">
        <OzContainer>
          <center>
            <h2>{translation("continuity_title", lang)}</h2>
            {translation("continuity_description", lang)}
          </center>
        </OzContainer>
      </OzBand>
      <OzBand variant="whitesmoke">
        <Console lang={lang} />
      </OzBand>
    </Layout>
  )

}
