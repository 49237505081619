import React from 'react';
import {translation} from "./../helpers";
import styled from 'styled-components';

import {OzContainer, OzRow, OzCol} from "@ozwol/webui";

const Icon = styled.div`
  & > * {
    font-size: 50px;
    color: #00141f;
  }
`;

export default function Benefits({lang}) {
  return (
    <OzContainer>
      <center>
        <h2>{translation("benefits_title", lang)}</h2>
        {translation("benefits_description", lang)}
      </center>
      <br/><br/>
      <OzRow gap="25">
        <OzCol widthmd="4">
          <center>
            <Icon>
              <span className="material-symbols-outlined">savings</span>
            </Icon>
            <h3>{translation("benefits_costs_title", lang)}</h3>
            {translation("benefits_costs_description", lang)}
          </center>
        </OzCol>
        <OzCol widthmd="4">
          <center>
            <Icon>
             <span className="material-symbols-outlined">timer</span>
            </Icon>
            <h3>{translation("benefits_time_title", lang)}</h3>
            {translation("benefits_time_description", lang)}
          </center>
        </OzCol>
        <OzCol widthmd="4">
          <center>
            <Icon>
              <span className="material-symbols-outlined">all_inclusive</span>
            </Icon>
            <h3>{translation("benefits_operate_title", lang)}</h3>
            {translation("benefits_operate_description", lang)}
          </center>
        </OzCol>
      </OzRow>
    </OzContainer>
  )

}
