import React, { useState, useEffect, useCallback, useLayoutEffect }  from 'react';
import { NavLink, Link, useLocation, useParams } from "react-router-dom";
// import {Helmet} from "react-helmet";
import styled from 'styled-components';
import {translation, isValidLang} from "./../helpers";

import {SILVERTREE, CHETWODEBLUE, LILAC, OzButton, OzContainer} from "@ozwol/webui";

import Topbar from "./Topbar";
import Footer from "./Footer";

const Content = styled.div`
  padding-top: 70px;
`;
const Rights = styled.div`
  background: #2E3D4F;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  color: #8A8C9B;
  padding-bottom: 45px;
`;
const Language = styled(Link)`
  color: #00141f;
  line-height: 42px;
  font-weight: 700;
  font-size: 14px;
`;
const ToTop = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

const Button = styled.div`
  cursor: pointer;
  font-size: 14px;  
  font-weight: 600;
  border-radius: 5px; 
  height: 30px;
  padding: 1px 14px 0px;
  display: inline-block;

  color: ${props => props.color === "silvertree" || props.color === "chetwodeblue" ? "#ffffff" : "#000000"};
  background-color: ${props => props.color === "silvertree" ? SILVERTREE : props.color === "chetwodeblue" ? CHETWODEBLUE : "#ffffff"};

  &:hover{
    ${props => props.color !== "silvertree" && props.color !== "chetwodeblue" ? "background-color: "+LILAC : ""}
  }
`;


const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}


const Layout = ({ canonicalSlug, children }) => {
  const {lang} = useParams();
  const location = useLocation();
  
  document.documentElement.setAttribute('lang', lang);
  window.gtag("event", "page_view",  {
    page_location: window.location.pathname + window.location.search
  });

  const [scrollListener, setScrollListener] = useState(false);
  const [showToTop, setShowToTop] = useState(null);

  const handleScroll = useCallback(() => {
    setShowToTop(window.scrollY > 100);
  }, []);

  useEffect(() => {
    if(!scrollListener){
      setScrollListener(true);
      window.addEventListener("scroll", handleScroll, false);
      handleScroll();
    }
  }, [handleScroll, scrollListener]);
  
  if(!isValidLang(lang)){
    window.location.replace("/en"+(canonicalSlug ? "/"+canonicalSlug : ""));
  }else{
    return (
      <Wrapper>
        <Topbar
          lang={lang}
          logoLink={"/"+lang}
          items={[
            <NavLink to={"/"+lang+"/retouch"}>
              {({ isActive }) => (<Button color={isActive ? "silvertree" : "menu"} noHover={isActive ? true : false}>{translation("header_retouch", lang)}</Button>)}
            </NavLink>,
            <NavLink to={"/"+lang+"/tagging"}>
              {({ isActive }) => (<Button color={isActive ? "silvertree" : "menu"} noHover={isActive ? true : false}>{translation("header_tagging", lang)}</Button>)}
            </NavLink>,
            <NavLink to={"/"+lang+"/pricing"}>
              {({ isActive }) => (<Button color={isActive ? "silvertree" : "menu"} noHover={isActive ? true : false}>{translation("header_prices", lang)}</Button>)}
            </NavLink>,
            <NavLink to={"/"+lang+"/blog"}>
              {({ isActive }) => (<Button color={isActive ? "silvertree" : "menu"} noHover={isActive ? true : false}>{translation("header_blog", lang)}</Button>)}
            </NavLink>,
            <NavLink to={"/"+lang+"/contact-us"}>
              {({ isActive }) => (<Button color={isActive ? "silvertree" : "chetwodeblue"} noHover={isActive ? true : false}>{translation("header_contactus", lang)}</Button>)}
            </NavLink>,
            (lang === "en" ? 
              <Language to={location.pathname.replace("/"+lang, "/it")}>IT</Language> 
            : 
              <Language to={location.pathname.replace("/"+lang, "/en")}>EN</Language>
            )
          ]}
        />

        <Content>
          {children}


          <Footer />

          <Rights>
            <OzContainer>
              {translation("footer_copyright_disclaimer", lang)}
            </OzContainer>
          </Rights>
          {showToTop ? 
            <ToTop onClick={() => {
              document.documentElement.scrollTo(0, 0);
            }}>
              <OzButton noMinWidth width={"42px"} size="big" variant={"chetwodeblue-o"}><span className="material-symbols-outlined">vertical_align_top</span></OzButton>
            </ToTop>
          : null }
        </Content>
      </Wrapper>
    )
  }
}

export default Layout;
