import React from 'react';
import styled from 'styled-components';
import {CDN} from "./../helpers";

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import {OzContainer} from "@ozwol/webui";


const Wrapper = styled.div`
`;
const Image = styled.div`
  border: 1px solid #D0D0D0;
  border-radius: 17px;
  box-shadow: 0px 3px 3px #00000019;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: 500px;
  max-width: 100%;

  & img{
    display: block;
    width: 100%;
    max-width: 100%;
  }
`;

const data = [
  "1.jpg",
  "2.jpg",
  "3.jpg",
  "4.jpg",
  "5.jpg",
  "6.jpg",
  "7.jpg",
  "8.jpg",
  "9.jpg",
  "10.jpg"
];


const RetouchHero = ({ lang }) => {

  return (
    <Wrapper>
      <OzContainer>

        <Image>
          <Fade
            duration={3000}
            arrows={false}
          >
            {data.map((image, i) => (
              <img
                key={i}
                src={CDN + "website-assets/" + image}
                alt={"Hero Retouch"}
              />
            ))}
          </Fade>
        </Image>
      </OzContainer>
    </Wrapper>
  )
}

export default RetouchHero;
