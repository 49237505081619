import React from 'react';
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
// import {Link} from "react-router-dom";
import {translation} from "./../helpers";

import {CHARCOAL, WHITESMOKE, OzContainer, OzRow, OzCol, OzLogo, OzButton} from "@ozwol/webui";


const Container = styled.div`
  padding: 35px 0px;
  width: 100%;
  background-color: ${CHARCOAL};
  color: ${WHITESMOKE};
  text-align: left;

  &, & * {
    box-sizing: border-box;
  }
`;



const Menu = styled.div`
  &:not(:last-child){
    margin-bottom: 20px;
  }
  
  padding: 10px 20px;
  border-radius: 4px;
  // border: 1px solid red;

  ${props => props.highlighted ? `
    background: `+WHITESMOKE+`20;

  ` : null}

`;

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
`;
const Items = styled.div`

`;

const Item = styled.div`
  font-size: 15px;
  font-weight: bold;
  line-height: 21px;
  font-weight: bold;
  padding-bottom: 3px;

  & a{
    color: ${WHITESMOKE};
    text-decoration: none;
  }
  & a:hover{
    color: ${WHITESMOKE};
    text-decoration: underline;
  }

`;

const Details = styled.div`
  margin-top: 15px;
  font-size: 15px;
  line-height: 25px;
`;
const Foot = styled.div`
  margin-top: 70px;
  font-size: 15px;
  text-align: center;
  line-height: 25px;
`;
const NewsletterDetail = styled.div`  
  margin-top: 15px;
  font-size: 14px;
  line-height: 25px;
`;



const Footer = () => {
  const {lang} = useParams();

  return (
    <Container>
      <OzContainer>
        <OzRow>
          <OzCol widthmd="3">
            <Menu>
              <OzLogo variant={"inverse"} width={"150px"} />
              <Details>
                Via A. Pacinotti 4/b<br/>
                37135 Verona (VR)<br/>
                {translation("address_nation", lang)}<br/>
                <br/>
                +39 045 2456369<br/>
              </Details>
            </Menu>
          </OzCol>
          <OzCol widthmd="3">
            <Menu>
              <Title>Ozwol.com</Title>
              <Items>
                <Item>
                  <Link to={"/"+lang}>{translation("footer_homepage", lang)}</Link>
                </Item>
                <Item>
                  <Link to={"/"+lang+"/retouch"}>{translation("footer_retouch", lang)}</Link>
                </Item>
                <Item>
                  <Link to={"/"+lang+"/tagging"}>{translation("footer_tagging", lang)}</Link>
                </Item>
                <Item>
                  <Link to={"/"+lang+"/pricing"}>{translation("footer_prices", lang)}</Link>
                </Item>
                <Item>
                  <Link to={"/"+lang+"/contact-us"}>{translation("footer_contactus", lang)}</Link>
                </Item>
              </Items>
            </Menu>
          </OzCol>
          <OzCol widthmd="3">
            <Menu>
              <Title>{translation("footer_documentation", lang)}</Title>
              <Items>
                <Item>
                  <a target="_blank" rel="noreferrer" href={"https://docs.ozwol.com/docs/getting-started"}>{translation("footer_docs", lang)}</a>
                </Item>
                <Item>
                  <a target="_blank" rel="noreferrer" href={"https://docs.ozwol.com/api-reference"}>{translation("footer_apireference", lang)}</a>
                </Item>
              </Items>
            </Menu>
            <Menu>
              <Title>{translation("footer_privacyandcookies", lang)}</Title>
              <Items>
                <Item>
                  <Link to={"/"+lang+"/privacy-policy"}>{translation("footer_privacypolicy", lang)}</Link>
                </Item>
                <Item>
                  <Link to={"/"+lang+"/cookie-policy"}>{translation("footer_cookiepolicy", lang)}</Link>
                </Item>
              </Items>
            </Menu>
          </OzCol>
          <OzCol widthmd="3">
            <Menu highlighted>
              <Title>{translation("footer_newsletter_title", lang)}</Title>
              
              <a target="_blank" rel="noreferrer" href={"https://mailchi.mp/439dfc71a8d0/newsletter-subscription"}>
                <OzButton width="100%" variant="chetwodeblue">{translation("footer_newsletter_button", lang)}</OzButton>
              </a>
              <NewsletterDetail>
              {translation("footer_newsletter_detail", lang)}
              </NewsletterDetail>
            </Menu>
              
          </OzCol>
          <OzCol widthmd="12">
            <Foot>
              <b>© {new Date().getFullYear()} Ozwol Technologies. {translation("footer_allrightsreserved", lang)}</b><br/>
              Ozwol Technologies S.r.l. - Via A. Pacinotti 4/b, 37135 Verona - Italia<br/>
              C.F./P.IVA: 04590420230 - REA: VR-433276 - Camera di Commercio di Verona - CS: € 10.000 I.V. - PEC: ozwol @ pec . net<br/>
            </Foot>
          </OzCol>
        </OzRow>
      </OzContainer>
    </Container>
  )
}

export default Footer
