import React from 'react';
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
import {translation, textTranslation, CDN} from "./../helpers";

import {SILVERTREE, OzBand, OzContainer, OzRow, OzCol, OzButton} from "@ozwol/webui";

import Layout from "./../components/Layout";
import Meta from './../components/Meta';
import HomepageHero from "./../components/HomepageHero";
// import AnalysisAnimation from "./../components/AnalysisAnimation";
// import ManipulationAnimation from "./../components/ManipulationAnimation";
import Benefits from "./../chunks/Benefits";

const Circle = styled.div`
  background: ${SILVERTREE};
  color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
`;

export default function Homepage({canonicalSlug}) {
  const {lang} = useParams();  

  return (
    <Layout canonicalSlug={canonicalSlug}>
      <Meta
        description={textTranslation("meta_homepage_description", lang)}
        canonicalSlug={canonicalSlug}
      />
      <OzBand variant="chetwodeblue-light">
        <OzContainer>
          <OzRow gap="25" align="center">
            <OzCol widthmd="6">
              <h1>{translation("home_title", lang)}</h1>
              {translation("home_description", lang)}<br/><br/>
              <Link to={"/"+lang+"/retouch"}><OzButton variant="chetwodeblue">{translation("header_retouch", lang)}</OzButton></Link> &nbsp;
              <Link to={"/"+lang+"/tagging"}><OzButton variant="silvertree">{translation("header_tagging", lang)}</OzButton></Link>
            </OzCol>
            <OzCol widthmd="6">
              <HomepageHero lang={lang}/>
            </OzCol>
          </OzRow>
        </OzContainer>
      </OzBand>
      <OzBand variant="white">
        <OzContainer size="big">
          <center><h2>{translation("scope_title", lang)}</h2></center>
          <br/>
          <OzRow gap="25">
            <OzCol widthmd="5">
              <center>
                <h3>{translation("scope_analysys_title", lang)}</h3>
                {translation("scope_analysys_description", lang)}
                <br/><br/>
                <img src={CDN+"website-assets/analisi_ok.gif"} style={{width: "300px", height: "300px", maxWidth: "100%"}} alt="analisys"/>
                <br/><br/>
                {/*<AnalysisAnimation lang={lang}/>*/}
              </center>
            </OzCol>
            <OzCol widthmd="2"></OzCol>
            <OzCol widthmd="5">
              <center>
                <h3>{translation("scope_editing_title", lang)}</h3>
                {translation("scope_editing_description", lang)}
                <br/><br/>
                <img src={CDN+"website-assets/editing_ok.gif"} style={{width: "300px", height: "300px", maxWidth: "100%"}} alt="editing"/>
                <br/><br/>
                <br/><br/>
                {/*<ManipulationAnimation lang={lang}/>*/}
              </center>
            </OzCol>
          </OzRow>
        </OzContainer>
      </OzBand>
      <OzBand variant="whitesmoke">
        <OzContainer>
          <center><h2>{translation("howitworks_title", lang)}</h2></center>
          <OzRow gap="25">
            <OzCol widthmd="4">
              <center>
                <Circle>1</Circle>
                {translation("howitworks_description1", lang)}
              </center>
            </OzCol>
            <OzCol widthmd="4">
              <center>
                <Circle>2</Circle>
                {translation("howitworks_description2", lang)}
              </center>
            </OzCol>
            <OzCol widthmd="4">
              <center>
                <Circle>3</Circle>
                {translation("howitworks_description3", lang)}
              </center>
            </OzCol>
            <OzCol widthmd="12">
              <center>{translation("howitworks_description", lang)}</center>
            </OzCol>
          </OzRow>
        </OzContainer>
      </OzBand>
      <OzBand variant="chetwodeblue-medium">
        <OzContainer>
          <center>
            <h2>{translation("professionals_title", lang)}</h2>
            {translation("professionals_description", lang)}
          </center>
        </OzContainer>
      </OzBand>
      <OzBand variant="white">
        <Benefits lang={lang} />
      </OzBand>
      <OzBand variant="whitesmoke">
        <OzContainer>
          <center>
            <h2>{translation("gotoplans_title", lang)}</h2>
            {translation("gotoplans_description", lang)}
            <br/><br/>
            <Link to={"/"+lang+"/pricing"}><OzButton variant="chetwodeblue">{translation("gotoplans_cta", lang)}</OzButton></Link>
          </center>
        </OzContainer>
      </OzBand>
    </Layout>
  )

}
