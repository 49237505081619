import React, {useState} from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import {translation, textTranslation} from "./../helpers";

import {SILVERTREE, BITTERSWEET, OzBand, OzContainer, OzRow, OzCol, OzButton, OzInput, OzCheckbox, OzTextarea} from "@ozwol/webui";

import Card from "./../components/Card";
import Layout from "./../components/Layout";
import Meta from './../components/Meta';



const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
`;
const Success = styled.div`
  color: ${SILVERTREE};
`;
const Error = styled.div`
  color: ${BITTERSWEET};
`;
const Privacy = styled.div`
  display: flex;
  align-items: first baseline;

  & > *:first-child{
    margin-right: 10px;
  }
`;

export default function ContactUs({canonicalSlug}) {
  const {lang} = useParams();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function validMail(mail)
  {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(mail);
  }

  function send(){
    setSuccess(null);
    setError(null);
    if(privacy){
      if(name && surname && email && message){
        if(validMail(email)){
          let body = "Nome: " + name + "\n";
              body+= "Cognome: " + surname + "\n";
              body+= "Email: " + email + "\n";
              body+= "Company: " + company + "\n";
              body+= "Oggetto: " + subject + "\n";
              body+= "Messaggio: " + message + "\n";
          const data = {
            "email": "noreply@ozwol.com",
            "subject": "Contatto da " + name + " " + surname + (company ? " ("+company+")" : ""),
            "body": body
          };
          axios.post('https://8x6uy6a1uh.execute-api.eu-west-1.amazonaws.com/v1/contact', data, {
            "Access-Control-Allow-Origin": true
          }).then(response => {
            if(response.data.response === "Success"){
              setSuccess(translation("contact_success", lang));
              setName("");
              setSurname("");
              setEmail("");
              setCompany("");
              setSubject("");
              setMessage("");
              setPrivacy(false);
            }else{
              setError(translation("contact_error_generic", lang));
            }
          }).catch(error => {
              setError(error.message);
          });
        }else{
          setError(translation("contact_error_invalidemail", lang));
        }
      }else{
        setError(translation("contact_error_mandatory", lang));
      }
    }else{
      setError(translation("contact_error_privacy", lang));
    }
  }

  return (
    <Layout canonicalSlug={canonicalSlug}>
      <Meta
        title={textTranslation("meta_contactus_title", lang)}
        description={textTranslation("meta_contactus_description", lang)}
        canonicalSlug={canonicalSlug}
      />
      <OzBand variant="whitesmoke">
        <OzContainer size="small">
          <center>
            <h1>{translation("contactus_title", lang)}</h1>
            {translation("contactus_description", lang)}<br/><br/>
          </center>
          <Card footerRight={<OzButton onClick={() => send()}>{translation("contact_send", lang)}</OzButton>}>
            <OzRow>
              <OzCol widthmd="12">
                <Label>{translation("contact_name", lang)} *</Label>
                <OzInput
                  width="100%"
                  value={name}
                  onChange={(val) => setName(val)}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <Label>{translation("contact_surname", lang)} *</Label>
                <OzInput
                  width="100%"
                  value={surname}
                  onChange={(val) => setSurname(val)}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <Label>{translation("contact_email", lang)} *</Label>
                <OzInput
                  width="100%"
                  value={email}
                  onChange={(val) => setEmail(val)}
                  type="email"
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <Label>{translation("contact_company", lang)}</Label>
                <OzInput
                  width="100%"
                  value={company}
                  onChange={(val) => setCompany(val)}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <Label>{translation("contact_subject", lang)}</Label>
                <OzInput
                  width="100%"
                  value={subject}
                  onChange={(val) => setSubject(val)}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <Label>{translation("contact_message", lang)} *</Label>
                <OzTextarea
                  width="100%"
                  value={message}
                  onChange={(val) => setMessage(val)}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <Privacy>
                  <OzCheckbox
                    label={translation("contact_privacy", lang)}
                    selected={privacy}
                    onChange={(val) => setPrivacy(val)}
                  /> 
                </Privacy>
              </OzCol>
              <OzCol widthmd="12">

                {success ?
                  <Success>
                    <br/>{success}<br/>
                  </Success>
                : null}

                {error ?
                  <Error>
                    <br/>{error}<br/>
                  </Error>
                : null}
              </OzCol>
            </OzRow>
          </Card>
        </OzContainer>
      </OzBand>
    </Layout>
  )

}
