import React from 'react';
import { useParams } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {textTranslation} from "./../helpers";

const Meta = ({title = null, description = null, image = null, canonicalSlug = null}) => {
  const {lang} = useParams();
  return (
    <Helmet>
      <title>{title && title !== "" ? title + " | Ozwol Technologies" : "Ozwol Technologies"}</title>
      <meta property="og:title" content={title && title !== "" ? title + " | Ozwol Technologies" : "Ozwol Technologies"} />
      {description ? 
        <meta name="description" content={description} />
      : 
        <meta name="description" content={textTranslation("meta_homepage_description", lang)} />
      }
      {description ? 
        <meta property="og:description" content={description} />
      : 
        <meta property="og:description" content={textTranslation("meta_homepage_description", lang)} />
      }
      {image ? 
        <meta property="og:image" content={image} />
      : 
        <meta property="og:image" content={"https://cdn.ozwol.cloud/ozwol/logo/ozwol-preview.png"} />
      }
      <meta property="og:type" content="website" />
      {canonicalSlug !== null ?
        <meta property="og:url" content={"https://www.ozwol.com/"+lang+(canonicalSlug ? "/"+canonicalSlug : "")} />
      : null }
      {canonicalSlug !== null ?
        <link rel="canonical" href={"https://www.ozwol.com/"+lang+(canonicalSlug ? "/"+canonicalSlug : "")} />
      : null }
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="UTF-8" />
    </Helmet>
  )
}

export default Meta;