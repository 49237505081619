import React from 'react';
import styled from 'styled-components';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import {OzContainer, OzTag} from "@ozwol/webui";
import {CDN} from "./../helpers";


const Wrapper = styled.div`
`;

const Block = styled.div`
  width: 500px;
  max-width: 100%;
  height: 350px;
  position: relative;
`;
const Element1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
  display: flex;

  @media (max-width: 991px){
    flex-direction: column;
  }
`;
const Element2 = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 2;
  display: flex;
  opacity: 20%;
  filter: blur(3px);

  transform: scale(0.7);
  transform-origin: bottom right;

  @media (max-width: 991px){
    flex-direction: column-reverse;
    text-align: right;
  }
`;
const Image = styled.div`
  border: 3px solid #FFFFFF;
  border-radius: 17px;
  box-shadow: 12px 12px 10px #00000019;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  height: 270px;

  @media (max-width: 991px){
    height: 200px;
  }

  & img{
    display: block;
    height: 100%;
    max-height: 100%;
  }
`;
const Tags = styled.div`
  margin: 20px;
`;

const data = [
  {
    "image": "htag-1.jpg",
    "tags": ["Leaf","Flowers","Ramage","Rose","Green","Black","Red"]
  },
  {
    "image": "htag-2.jpg",
    "tags": ["bathroom","Design","Architecture","Stone","Marble"]
  },
  {
    "image": "htag-3.jpg",
    "tags": ["horse","mestengo","White","Gray","Spot"]
  },
  {
    "image": "htag-4.gif",
    "tags": ["fashion show","Runway","neckline Across","neckline Sweetheart"]
  },
  {
    "image": "htag-5.jpg",
    "tags": ["Michael Kors","tailoring","Belt","White"]
  }
];


const TaggingHero = ({ lang }) => {

  return (
    <Wrapper>
      <OzContainer>
        <Fade
          duration={3000}
          arrows={false}
        >
          {Array(data.length).fill(1).map((el, i) =>
            <Block key={i}>
              <Element1>
                <Image>
                  <img
                    src={CDN + "website-assets/"+data[i].image}
                    alt={"Hero Tagging"}
                  />
                </Image>
                <Tags>
                  {data[i].tags.map((tag, i) => (
                    <React.Fragment key={i}>
                      <OzTag variant="grey" size="big">{tag}</OzTag><br/>
                    </React.Fragment>
                  ))}
                </Tags>
              </Element1>
              <Element2>
                <Image>
                  <img
                    src={CDN + "website-assets/"+data[i+1 >= data.length ? 0 : i+1].image}
                    alt={"Hero Tagging"}
                  />
                </Image>
                <Tags>
                  {data[i+1 >= data.length ? 0 : i+1].tags.map((tag, i) => (
                    <React.Fragment key={i}>
                      <OzTag variant="grey" size="big">{tag}</OzTag><br/>
                    </React.Fragment>
                  ))}
                </Tags>
              </Element2>
            </Block>
          )}
        </Fade>
      </OzContainer>
    </Wrapper>
  )
}

export default TaggingHero;
