import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import {CDN} from "./../helpers";

const Wrapper = styled.div`
  position: relative;
  height: 300px;
  width: 100%;


  & .imageganger-element{
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    box-shadow: 0px 3px 3px #00000019;
    overflow: hidden;
  }

  & .imageganger-wrapper{
    position: absolute;
    margin-top: -100px;
    margin-left: -100px;
    width: 200px;
    height: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  & .imageganger-wrapper-0{
    top: 31%;
    left: 21%;
    z-index: 5;
  }
  & .imageganger-wrapper-1{
    top: 64%;
    left: 80%;
    z-index: 4;
  }
  & .imageganger-wrapper-2{
    top: 31%;
    left: 63%;
    z-index: 3;
  }
  & .imageganger-wrapper-3{
    top: 68%;
    left: 35%;
    z-index: 2;
  }
  @media (max-width: 991px){
    transform: scale(0.85);
  }
`;


const HomepageHero = ({ lang }) => {
  let ref = useRef(null);
   var images = [];



  useEffect(() => {


           // var ganger = null;
    // var isFocused = false;
    // var isVisible = false;
    // var isPlaying = false;
    // console.log(ref.current);

      /*temp*/

       images.push({url: CDN+"website-assets/H1.jpg", width: 600, height: 312});
       images.push({url: CDN+"website-assets/H2.jpg", width: 600, height: 589});
       images.push({url: CDN+"website-assets/H3.jpg", width: 600, height: 400});
       images.push({url: CDN+"website-assets/H4.jpg", width: 600, height: 502});
       images.push({url: CDN+"website-assets/H5.jpg", width: 600, height: 600});
       images.push({url: CDN+"website-assets/H6.jpg", width: 430, height: 600});
       images.push({url: CDN+"website-assets/H7.jpg", width: 600, height: 600});
       images.push({url: CDN+"website-assets/H8.jpg", width: 399, height: 600});
       images.push({url: CDN+"website-assets/H9.jpg", width: 600, height: 400});
       images.push({url: CDN+"website-assets/H10.jpg", width: 600, height: 337});
       images.push({url: CDN+"website-assets/H11.jpg", width: 600, height: 600});
       images.push({url: CDN+"website-assets/H12.jpg", width: 600, height: 400});
       images.push({url: CDN+"website-assets/H13.jpg", width: 399, height: 600});
       images.push({url: CDN+"website-assets/H14.jpg", width: 600, height: 600});
       images.push({url: CDN+"website-assets/H15.jpg", width: 600, height: 400});
       images.push({url: CDN+"website-assets/H16.jpg", width: 600, height: 512});
       images.push({url: CDN+"website-assets/H17.jpg", width: 600, height: 400});
       images.push({url: CDN+"website-assets/H18.jpg", width: 600, height: 516});
       images.push({url: CDN+"website-assets/H19.jpg", width: 400, height: 600});
       images.push({url: CDN+"website-assets/H20.jpg", width: 600, height: 600});
       images.push({url: CDN+"website-assets/H21.jpg", width: 600, height: 374});
       images.push({url: CDN+"website-assets/H22.jpg", width: 422, height: 600});
       images.push({url: CDN+"website-assets/H23.jpg", width: 600, height: 600});
       images.push({url: CDN+"website-assets/H24.jpg", width: 301, height: 600});

        // images = images.forEach((item) => item.width = item.width/3);
        // images = images.forEach((item) => item.height = item.height/3);

       // testVisibility();
       // gangerToggler();

       // $(window).on('resize scroll', function() {
       //    testVisibility();
       // });

       // function testVisibility(){
       //    var imagegangerTop = $("#imageganger").offset().top;
       //    var imagegangerBottom = imagegangerTop + $("#imageganger").outerHeight();
       //    var viewportTop = $(window).scrollTop();
       //    var viewportBottom = viewportTop + $(window).height();
       //    if(imagegangerBottom > viewportTop && imagegangerTop < viewportBottom){
       //       isVisible = true;
       //    }else{
       //       isVisible = false;
       //    }
       //    gangerToggler();
       // }

       // function gangerToggler(){
       //    if(isFocused && isVisible){
             gangerStarter();
       //    }else{
       //       gangerStopper();
       //    }
       // }

       function gangerStarter(){
          // if(ganger != null){
          //    if(!isPlaying){
          //       // console.log("play");
          //       ganger.play();
          //       isPlaying = true;
          //    }
          // }else{
             // console.log("create");
             new window.imageganger(ref.current, {
             // ganger = new window.imageganger(ref.current, {
                elements: 4,
                images: images,
                imageScale: 0.30,
                changeTiming: 5000,
                changeTimingDelta: 4400,
                animationTiming: 500,
                animationTimingDelta: 50
             });
             // isPlaying = true;
          // }
       }
       // function gangerStopper(){
       //    if(ganger != null){
       //       if(isPlaying){
       //          // console.log("pause");
       //          ganger.pause();
       //          isPlaying = false;
       //       }
       //    }
       // }


       /*focus checker*/
       // (function() {
       //    var hidden = "hidden";
       //
       //    // Standards:
       //    if (hidden in document)
       //       document.addEventListener("visibilitychange", onchange);
       //    else if ((hidden = "mozHidden") in document)
       //       document.addEventListener("mozvisibilitychange", onchange);
       //    else if ((hidden = "webkitHidden") in document)
       //       document.addEventListener("webkitvisibilitychange", onchange);
       //    else if ((hidden = "msHidden") in document)
       //       document.addEventListener("msvisibilitychange", onchange);
       //    // IE 9 and lower:
       //    else if ("onfocusin" in document)
       //       document.onfocusin = document.onfocusout = onchange;
       //    // All others:
       //    else
       //       window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
       //
       //    function onchange (evt) {
       //       var v = "visible";
       //       var h = "hidden";
       //       var evtMap = {
       //          focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
       //       };
       //
       //       evt = evt || window.event;
       //
       //       if (evt.type in evtMap){
       //          document.body.className = evtMap[evt.type];
       //          if(evtMap[evt.type] == "visible"){
       //             isFocused = true;
       //          }else{
       //             isFocused = false;
       //          }
       //       }else{
       //          document.body.className = this[hidden] ? "hidden" : "visible";
       //          if(!this[hidden]){
       //             isFocused = true;
       //          }else{
       //             isFocused = false;
       //          }
       //       }
       //       gangerToggler();
       //    }
       //
       //    // set the initial state (but only if browser supports the Page Visibility API)
       //    if(document[hidden] !== undefined){
       //       onchange({type: document[hidden] ? "blur" : "focus"});
       //    }
       // })();


  });

  return (
    <Wrapper id="imageganger" ref={ref}></Wrapper>
  )
}

export default HomepageHero;
