import React from 'react';
import { Link } from "react-router-dom";
import {translation, CDN} from "./../helpers";

import {OzContainer, OzButton} from "@ozwol/webui";


export default function Console({lang}) {
  return (
    <OzContainer>
      <center>
        <h2>{translation("console_title", lang)}</h2>
        {translation("console_description", lang)}<br/>
        <br/>
        <img src={CDN + "website-assets/console.png"} style={{width: "900px", maxWidth: "100%"}} alt="console"/>
        <br/><br/>
        <Link to={"/"+lang+"/contact-us"}><OzButton variant="chetwodeblue">{translation("console_cta", lang)}</OzButton></Link>
      </center>
    </OzContainer>
  )

}
