import React from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import {translation, textTranslation} from "./../helpers";

import jsonScripts from "./../data/tagging.json";

import {OzBand, OzContainer, OzRow, OzCol, OzIcon} from "@ozwol/webui";

import Layout from "./../components/Layout";
import Meta from './../components/Meta';
import Scripts from "./../components/Scripts";
import Console from "./../chunks/Console";
import Benefits from "./../chunks/Benefits";
import TaggingHero from "./../components/TaggingHero";

const Divider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px dashed #909090;
`;
const H1Icon = styled.span`
  display: inline-block;
  position: relative;
  bottom: -13px;
`;

export default function ImageSearch({canonicalSlug}) {
  const {lang} = useParams();
  return (
    <Layout canonicalSlug={canonicalSlug}>
      <Meta
        title={textTranslation("meta_tagging_title", lang)}
        description={textTranslation("meta_tagging_description", lang)}
        canonicalSlug={canonicalSlug}
      />
      <OzBand variant="silvertree-light">
        <OzContainer>
          <OzRow gap="25" align="center">
            <OzCol widthmd="6">
              <h1>{translation("tagging_title", lang)}</h1>
              {translation("tagging_description", lang)}
            </OzCol>
            <OzCol widthmd="6">
              <TaggingHero lang={lang} />
            </OzCol>
          </OzRow>
        </OzContainer>
      </OzBand>
      <OzBand variant="white">
        <OzContainer>
          <OzRow gap="25">
            <OzCol widthmd="6">
              <center>
                <h3>{translation("solution_realtime_title", lang)}</h3>
                {translation("solution_realtime_description", lang)}
              </center>
            </OzCol>
            <OzCol widthmd="6">
              <center>
                <h3>{translation("solution_storage_title", lang)}</h3>
                {translation("solution_storage_description", lang)}
              </center>
            </OzCol>
          </OzRow>
        </OzContainer>
      </OzBand>

      <Divider />

      <Scripts items={jsonScripts} lang={lang} format="vertical" />

      <OzBand variant="silvertree-medium">
        <OzContainer>
          <center>
            <h2><H1Icon><OzIcon name="api" size="60px" color="#00141f" /></H1Icon>{translation("api_title", lang)}</h2>
            {translation("api_description", lang)}
          </center>
        </OzContainer>
      </OzBand>
      <OzBand variant="whitesmoke">
        <Console lang={lang} />
      </OzBand>
      <OzBand variant="white">
        <Benefits lang={lang} />
      </OzBand>
    </Layout>
  )

}
