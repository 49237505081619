import React, {useEffect} from 'react';
import { useParams } from "react-router-dom";
import {translation, textTranslation} from "./../helpers";

import {OzBand, OzContainer} from "@ozwol/webui";

import Layout from "./../components/Layout";
import Meta from './../components/Meta';

export default function CookiePolicy({canonicalSlug}) {
  const {lang} = useParams();
  let iubendaId = null;
  switch(lang) {
    case "it":
      iubendaId = "18740113";
      break;
    default:
      iubendaId = "63364403";
      break;
  }

  useEffect(() => {
    var s = document.createElement("script");
    let tag = document.getElementsByTagName("script")[0];
    s.src="https://cdn.iubenda.com/iubenda.js";
    tag.parentNode.insertBefore(s,tag);
  }, []);

  return (
    <Layout canonicalSlug={canonicalSlug}>
      <Meta
        title={textTranslation("meta_cookiepolicy_title", lang)}
        description={textTranslation("meta_cookiepolicy_description", lang)}
        canonicalSlug={canonicalSlug}
      />
      <OzBand variant="whitesmoke">
        <OzContainer>
          <center>
            <h1>{translation("cookiepolicy_title", lang)}</h1>
          </center>
          <a href={`https://www.iubenda.com/privacy-policy/${iubendaId}/cookie-policy`} className="iubenda-nostyle no-brand iubenda-embed  iub-body-embed" title="Cookie Policy">Loading...</a>
        </OzContainer>
      </OzBand>
    </Layout>
  )

}
