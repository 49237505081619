import React from 'react';
import { NavLink, useParams } from "react-router-dom";
import styled from 'styled-components';
import {textTranslation} from "../helpers";


import {OzBand, OzContainer, OzRow, OzCol, CHETWODEBLUE, BLACKPEARL, OzTypo} from "@ozwol/webui";

import Layout from "../components/Layout";
import Meta from '../components/Meta';


import Art1ColorSwatch from "./BlogArticles/Art1-ColorSwatch";



const NavTitle = styled.div`
  
`;
const Item = styled.div`
  color: ${props => props.active ? CHETWODEBLUE : BLACKPEARL};
`;

export default function BlogDetail({canonicalSlug}) {
  const {lang, article} = useParams();

  return (
    <Layout canonicalSlug={canonicalSlug}>
      <Meta
        title={textTranslation("meta_retouch_title", lang)}
        description={textTranslation("meta_retouch_description", lang)}
        canonicalSlug={canonicalSlug}
      />
      <OzBand variant="white">
        <OzContainer>
          <OzRow gap="25">
            <OzCol widthmd="3">
              <NavTitle><OzTypo variant="h2w">Articles</OzTypo></NavTitle>
              <NavLink to={"/"+lang+"/blog/1-colorswatch"}>
                {({ isActive }) => (<Item active={isActive} >1. ColorSwatch</Item>)}
              </NavLink>
            </OzCol>
            <OzCol widthmd="9">
              {(() => {
                switch(article){
                  case "1-colorswatch":
                    return <Art1ColorSwatch />;
                  default:
                    window.location.href = "blog/1-colorswatch";
                    break;
                }
              })()}
            </OzCol>
          </OzRow>
        </OzContainer>
      </OzBand>      
    </Layout>
  )

}

