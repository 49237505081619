import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import "./Global.css";

import Homepage from "./pages/Homepage";
import Retouch from "./pages/Retouch";
import Tagging from "./pages/Tagging";
import Pricing from "./pages/Pricing";
import BlogDetail from "./pages/BlogDetail";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import NotFound from "./pages/NotFound";

const Redirect = ({to}: {children: JSX.Element}) => {
  window.location.replace(to);
}

function App() {
  let userLang = navigator.language || navigator.userLanguage; 
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={"/"+(
              userLang === "it" ? "it" : "en"
            )} replace />}
          />
          <Route exact path="/:lang" element={<Homepage canonicalSlug="" />} />
          <Route exact path="/:lang/retouch" element={<Retouch canonicalSlug="retouch" />} />
          <Route exact path="/:lang/tagging" element={<Tagging canonicalSlug="tagging" />} />
          <Route exact path="/:lang/pricing" element={<Pricing canonicalSlug="pricing" />} />
          <Route exact path="/:lang/blog" element={<BlogDetail canonicalSlug="blog" />} />
          <Route exact path="/:lang/blog/:article" element={<BlogDetail />} />
          <Route exact path="/:lang/privacy-policy" element={<PrivacyPolicy canonicalSlug="privacy-policy" />} />
          <Route exact path="/:lang/cookie-policy" element={<CookiePolicy canonicalSlug="cookie-policy" />} />
          <Route exact path="/:lang/contact-us" element={<ContactUs canonicalSlug="contact-us" />} />
          <Route exact path="/newsletter" element={<Redirect to="https://mailchi.mp/439dfc71a8d0/newsletter-subscription"/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
